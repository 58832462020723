<template>
  <van-address-list
    v-model="chosenAddressId"
    :list="list"
    default-tag-text="默认"
    @add="onAdd"
    @edit="onEdit"
    @select="onSelect"
  />
  <van-popup v-model:show="show" position="bottom">
    <van-address-edit
      :address-info="addressInfo"
      :area-list="areaList"
      show-delete
      show-set-default
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
    />
  </van-popup>
</template>

<script>
import areaList from '@/util/area'
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      originalList: [],
      show: false,
      chosenAddressId: '1',
      list: [],
      areaList,
      searchResult: [],
      addressInfo: {},
      buyerId: Cookies.get('buyerId'),
      back: useRoute.query.back ?? '0'
    })
    const onChangeDetail = (val) => {
      if (val) {
        this.searchResult = [
          {
            name: '黄龙万科中心',
            address: '杭州市西湖区'
          }
        ]
      } else {
        this.searchResult = []
      }
    }
    const onSave = (content) => {
      console.log(content)
      if (typeof content.id !== 'undefined') {
        post('/receiver.update', {
          receiverId: content.id,
          name: content.name,
          phone: content.tel,
          province: content.province,
          city: content.city,
          county: content.county,
          address: content.addressDetail,
          areaCode: content.areaCode,
          choice: content.isDefault
        }).then(res => {
          if (res.code === 0) {
            init()
            state.show = false
          } else {
            Toast(res.msg)
          }
        })
      } else {
        post('/receiver.create', {
          buyerId: state.buyerId,
          name: content.name,
          phone: content.tel,
          province: content.province,
          city: content.city,
          county: content.county,
          address: content.addressDetail,
          areaCode: content.areaCode,
          choice: content.isDefault
        }).then(res => {
          if (res.code === 0) {
            init()
            state.show = false
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const onDelete = (content) => {
      if (typeof content.id !== 'undefined') {
        console.log(content.id)
        post('/receiver.delete', {
          receiverIds: content.id.toString().split(',')
        }).then(res => {
          if (res.code === 0) {
            init()
            state.show = false
          } else {
            Toast(res.msg)
          }
        })
      }
    }
    const onSelect = (item, index) => {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const output = state.originalList.find((_item, _index, arr) => {
        return _item.receiverId === item.id
      })
      post('/receiver.update', {
        receiverId: output.receiverId,
        name: output.name,
        phone: output.phone,
        province: output.province,
        city: output.city,
        county: output.county,
        address: output.address,
        areaCode: output.areaCode,
        choice: output.choice
      }).then(res => {
        Toast.clear()
        if (res.code === 0) {
          if (state.back === '1') {
            useRouter.go(-1)
          } else {
            init()
          }
        } else {
          Toast(res.msg)
        }
      })
    }
    const onEdit = (item, index) => {
      state.show = true
      const output = state.originalList.find((_item, _index, arr) => {
        return _item.receiverId === item.id
      })
      state.addressInfo = {
        id: output.receiverId,
        name: output.name,
        tel: output.phone,
        province: output.province,
        city: output.city,
        county: output.county,
        addressDetail: output.address,
        areaCode: output.areaCode,
        postalCode: '',
        isDefault: output.choice
      }
      console.log(state.addressInfo.id)
    }
    const onAdd = () => {
      state.show = true
      state.addressInfo = {}
    }
    const init = () => {
      post('/receiver.list', {
        buyerId: state.buyerId
      }).then(res => {
        state.originalList = res.data.content
        state.list = []
        for (const item of res.data.content) {
          state.list.push({
            id: item.receiverId,
            name: item.name,
            tel: item.phone,
            address: item.address,
            isDefault: item.choice
          })
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSelect,
      onAdd,
      onEdit,
      onSave,
      onDelete,
      onChangeDetail
    }
  }
}
</script>
